<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <title-text />
          <summary-content />
          <content-text />
          <ordering-number />
        </b-card>
        <b-row class="match-height">
          <b-col>
            <icon-card />
          </b-col>
          <b-col>
            <image-card />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="11"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Service_categories/elements/TitleText.vue'
import SummaryContent from '@/views/Admin/Service_categories/elements/SummaryContent.vue'
import ContentText from '@/views/Admin/Service_categories/elements/ContentText.vue'
import OrderingNumber from '@/views/Admin/Service_categories/elements/OrderingNumber.vue'
import IconCard from '@/views/Admin/Service_categories/elements/IconCard.vue'
import ImageCard from '@/views/Admin/Service_categories/elements/Image.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    SeoForm,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    TitleText,
    SummaryContent,
    ContentText,
    OrderingNumber,
    IconCard,
    ImageCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceCategories/dataItem']
    },
  },
}
</script>
