<template>
  <b-form-group
    label="Özet Metin"
    label-for="summary"
  >
    <b-form-textarea
      id="summary"
      v-model="itemData.summary"
      placeholder="Özet Metin"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'SummaryText',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceCategories/dataItem']
    },
  },
}
</script>
